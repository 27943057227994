@import '../common/variables';
@import '../common/mixins';
@import './selectableItemBox';

body {
  font-size: 16px;
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-capitalize {
  text-transform: capitalize;
}

.primary-heading-color {
  color: $primary-heading-color;
}

.primary-color {
  color: $primary-color;
}

.mobile-container {
  display: flex;
  justify-content: center;
  background: #fafafa;
}

#mobile-device {
  width: 480px;
  max-width: 100%;
  background: white;
  min-height: 100vh;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  h1.ant-typography,
  h2.ant-typography,
  h3.ant-typography,
  h4.ant-typography {
    color: $primary-heading-color;
  }

  h2.ant-typography {
    font-weight: 500;
    font-size: 28px;
  }

  h4.ant-typography {
    font-weight: 500;
    font-size: 18px;
  }

  ul {
    list-style-type: disc;
    margin: 0;
  }

  .ant-form-item-label.ant-col-24.ant-col-rtl label {
    width: 100%;
  }

  .ant-input.ant-input-lg {
    padding: 11.5px 11px;
  }

  .ant-form-item {
    .react-tel-input .ant-input {
      width: 100%;
      height: auto;
      padding-left: 48px;
    }

    &.ant-row-rtl {
      .react-tel-input {
        direction: ltr !important;
      }
    }
  }
}

.language-label * {
  width: 90px !important;
  text-align: right !important;
}

.ant-row-rtl .language-label * {
  width: 90px !important;
  text-align: left !important;
}

.icon-social-share {
  display: inline-block;
  margin: 15px 10px 20px;
  @media (min-width: 576px) {
    margin: 15px 20px 25px;
  }
}
