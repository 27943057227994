.box {
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: $white;
  border-radius: $border-radius;
  min-height: 50px;
  font-size: 18px;
  color: $primary-text-color;

  &.selected {
    background-color: $primary-selected;
    border: unset;
  }

  &.selectable {
    cursor: pointer;
  }
}
